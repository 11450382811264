import { render, staticRenderFns } from "./Pruebas.vue?vue&type=template&id=340c0bea&scoped=true&"
import script from "./Pruebas.vue?vue&type=script&lang=js&"
export * from "./Pruebas.vue?vue&type=script&lang=js&"
import style0 from "./Pruebas.vue?vue&type=style&index=0&id=340c0bea&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340c0bea",
  null
  
)

export default component.exports
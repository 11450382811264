<template>
  <div class="wrapper">
    <div class="content">
      <h1 style="text-align: left">
        Verificación para vehículos de gasolina y diésel
      </h1>

      <div class="intro-container">
        <p class="intro">
          Verificación para vehículos de gasolina, Gas natural, Gas L.P y Diésel (ligeros y pesados). <br>
          Pruebas específicas y especializadas para cada vehículo.
        </p>
        <router-link to="/tips" ><button class="router-btn">Tips para emisiones</button></router-link>
        <router-link to="/limites" ><button class="router-btn">Limites de pruebas</button></router-link>
      </div>
      <h2>Pruebas combustible gasolina</h2>
      <div class="gasolina">
        <div class="card">
          <h4>ESTÁTICA</h4>
          <div>
            <img
              src="@/assets/images/pruebas/prueba_estatica_petrol.svg"
              alt=""
            />
          </div>
          <p>
            En la prueba estática se obtienen 5 valores para determinar si el vehículo cumple 
            con los límites máximos permisibles de emisiones en gases contaminantes.
            <br />
            Los vehículos indicados para esta prueba son los modelos anteriores al año 2006 
            o aquellos que no son viables para la prueba Dinámica, por ejemplo, vehículos de tracción integral, 4x4, entre otros.
          </p>
        </div>
        <div class="card">
          <h4>DINÁMICA</h4>
          <div>
            <img
              src="@/assets/images/pruebas/prueba_dinamica_petrol.svg"
              alt=""
            />
          </div>
          <p>
            En esta prueba hay un valor adicional, el óxido de nitrógeno “NOx” (principal precursor de ozono al reaccionar con la atmósfera),
            el cual se obtiene al realizar la prueba sobre el banco de ensayos móvil “DINAMOLQUE” ®, cuyo elemento principal PARA SU OPERACIÓN
            es el dinamómetro de rodillos.
          </p>
        </div>
      </div>
      <h2>Prueba combustible diésel</h2>
      <div class="diesel">
        <h4>ESTÁTICA</h4>
        <div>
          <img
            src="@/assets/images/pruebas/prueba_estatica_diesel.svg"
            alt=""
          />
        </div>
        <p>
         Prueba dirigida para vehículos a base de combustible diésel, su aplicación y valores arrojan diferentes contaminantes, 
         como resultado se determina su cumplimiento en las emisiones predeterminadas para los de su tipo.
        </p>
      </div>
      <button style="margin-top: 80px;" class="red">Agenda tu cita</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pruebas",
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
.content{
  margin-bottom: 100px;
  h1 {
    color: $blue-light;
  }
  h2 {
    color: $black;
  }
  h4 {
    text-transform: uppercase;
    color: $blue-light;
  }
  .intro-container {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    button:hover {
      background-color: white;
      border:1px solid $blue-dark;
      color: $blue-dark;
    }
    @media screen and (max-width: 530px) {
        flex-direction: column;
        button {
          margin-top: 10px;
        }
     }    
    .intro{
      width: 50%;
      text-align: left;
      @media screen and (max-width: 530px) {
        width: 100%;
     }

  }
  }

  .gasolina {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    // flex-wrap: wrap;
    
    .card {
      width: 300px;
      margin: 20px;
      p{
        text-align: left;
      }
    }
    @media screen and (max-width: 800px) {
       .card{
         width: 500px;
         margin: auto;
         img{
           width: 300px;
         }
       }
       flex-direction: column;
     }
     @media screen and (max-width: 530px) {
       .card{
         width: 100%;
         margin: auto;
         img{
           width: 50%;
         }
       }
      //  flex-direction: column;
     }
  }
  .diesel {
    width: 500px;
    margin: auto;
     p{
        text-align: left;
      }
      @media screen and (max-width: 530px) {
      width: 100%;
      }

  }
}
</style>
